export const environment = {
  production: true,
  development: false,
  test: false,
  brand: 'Vyv',
  apiV2RootUrl: 'https://sta.assurance.vyv-conseil.fr/api/',
  baseUrl: 'https://sta.assurance-habitation.vyv-conseil.fr/',
  matomotoKey: 'CHANGEME',
  appInsightsKey: '05541a49-5db5-479b-bb9c-782d35793148',
  assConfort: 'https://saboiarsbackofficewh8xm.blob.core.windows.net/public/Plaquette_Assurance_Habitation_Confort_Tableau%20des%20garanties.pdf',
  assParisienne:
    'https://saboiarsbackofficewh8xm.blob.core.windows.net/public/Plaquette_Assurance_Habitation_Parisienne_Tableau%20des%20garanties.pdf',
  assTourquennoise:
    'https://saboiarsbackofficewh8xm.blob.core.windows.net/public/Plaquette_Assurance_Habitation_Tourquennoise_Tableau%20des%20garanties.pdf',
  assSolidaire:
    'https://saboiarsbackofficewh8xm.blob.core.windows.net/public/Plaquette_Assurance_Habitation_Solidaire_Tableau%20des%20garanties.pdf',
  matomoSiteId: 6,
  matomoHostUrl: 'https://matomo-recette.vyv-extension-essai.aws.mgen.fr/',
  matomoScriptUrl: 'https://matomo-recette.vyv-extension-essai.aws.mgen.fr/js/container_682wvypB.js',
  modeleCourrierResiliation: 'https://saboiarsbackofficewh8xm.blob.core.windows.net/public/ModeleCourrier.docx',
  msalConfig: {
    auth: {
      clientId: '35b3294d-05b1-475b-a4fe-34d018382b09',
      authority: 'https://login.microsoftonline.com/6f12f368-4cb1-4b07-9574-dd2cdf95d9c1',
      redirectUri: 'https://sta.assurance-habitation.vyv-conseil.fr/'
    }
  },
  apiConfig: {
    scopes: ['api://boiard-sta-01-back-office/Read.All', 'api://boiard-sta-01-back-office/Write.All'],
    uri: 'https://sta.assurance.vyv-conseil.fr/api/'
  }
};
